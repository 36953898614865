<template>
  <div>
    <Select
      label="Ürün Tipi *"
      v-model="form.productType"
      :defaultValue="selectedItem.productType"
      :state="validateState('productType')"
      :options="[
        {
          value: 'Popup',
          text: 'Popup',
        },
        {
          value: 'Spin',
          text: 'Spin',
        },
        {
          value: 'Sticky',
          text: 'Sticky',
        },
        {
          value: 'SocialProof',
          text: 'Social Proof',
        },
        {
          value: 'Search',
          text: 'Search',
        },
        {
          value: 'Tag',
          text: 'Tag',
        },
        ,
        {
          value: 'Recommendation',
          text: 'Recommendation',
        },
      ]"
    />

    <Input
      label="Ürün Adı *"
      v-model="form.name"
      :defaultValue="selectedItem.name"
      :state="validateState('name')"
    />

    <NumberInput
      label="Fiyat *"
      v-model="form.price"
      :defaultValue="selectedItem.price"
      :state="validateState('price')"
    />

    <Checkbox
      formGroupLabel="Durum *"
      label="Aktif"
      v-model="form.isActive"
      :defaultValue="
        selectedItem.isActive === true || selectedItem.isActive === false
          ? selectedItem.isActive.toString()
          : ''
      "
    />
  </div>
</template>

<script>
// Utils
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'InsertUpdateOperations',
  mixins: [validationMixin],
  props: {
    confirmModal: Boolean,
    selectedItem: String | Object,
  },
  data() {
    return {
      loading: false,
      form: {
        productType: '',
        name: '',
        isActive: true,
        price: '',
      },
    };
  },
  validations: {
    form: {
      productType: {
        required,
      },
      name: {
        required,
      },
      isActive: {
        required,
      },
      price: {
        required,
      },
    },
  },
  created() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    confirm() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.$emit('resetInsertUpdateForm', false);
        return;
      }
      let payload = {
        productType: this.form.productType,
        name: this.form.name,
        price: this.form.price,
        isActive: this.form.isActive,
      };

      this.$dbFunctions
        .insertOrUpdate(`product`, this.selectedItem.id, payload)
        .then((res) => {
          this.$dbFunctions.setResponse(this, res);
          this.$emit('resetInsertUpdateForm', true);
          this.formReset();
        })
        .catch((err) => {
          this.$dbFunctions.setResponse(this, err);
          this.$emit('resetInsertUpdateForm', false);
        })
        .finally(() => {});
    },
    formReset() {
      Object.keys(this.form).forEach((key) => (this.form[key] = ''));
      this.$v.$reset();
    },
  },
  watch: {
    confirmModal(newVal) {
      if (newVal) {
        this.confirm();
      }
    },
    selectedItem(newVal) {
      this.formReset();
    },
  },
};
</script>
