var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Select',{attrs:{"label":"Ürün Tipi *","defaultValue":_vm.selectedItem.productType,"state":_vm.validateState('productType'),"options":[
      {
        value: 'Popup',
        text: 'Popup',
      },
      {
        value: 'Spin',
        text: 'Spin',
      },
      {
        value: 'Sticky',
        text: 'Sticky',
      },
      {
        value: 'SocialProof',
        text: 'Social Proof',
      },
      {
        value: 'Search',
        text: 'Search',
      },
      {
        value: 'Tag',
        text: 'Tag',
      },
      ,
      {
        value: 'Recommendation',
        text: 'Recommendation',
      },
    ]},model:{value:(_vm.form.productType),callback:function ($$v) {_vm.$set(_vm.form, "productType", $$v)},expression:"form.productType"}}),_c('Input',{attrs:{"label":"Ürün Adı *","defaultValue":_vm.selectedItem.name,"state":_vm.validateState('name')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('NumberInput',{attrs:{"label":"Fiyat *","defaultValue":_vm.selectedItem.price,"state":_vm.validateState('price')},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}}),_c('Checkbox',{attrs:{"formGroupLabel":"Durum *","label":"Aktif","defaultValue":_vm.selectedItem.isActive === true || _vm.selectedItem.isActive === false
        ? _vm.selectedItem.isActive.toString()
        : ''},model:{value:(_vm.form.isActive),callback:function ($$v) {_vm.$set(_vm.form, "isActive", $$v)},expression:"form.isActive"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }